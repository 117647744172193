window.addEventListener("load", function() {
  var notification = document.getElementById("notification");
  var dismissButton = document.getElementById('dismiss');

  const minutes = new Date().getMinutes();
  if (minutes == "0") {
    var formattedMinutes = "It's just past the hour";
  } else if (minutes == "1") {
    var formattedMinutes = "It's 1 minute past the hour";
  } else {
    var formattedMinutes = "It's " + minutes + " minutes past the hour";
  }

  document.getElementById("minutes").innerHTML = formattedMinutes;

  setTimeout(function() {
    notification.classList.add("visible");
  }, 2000);

  dismiss.addEventListener('click', function(event) {
    notification.classList.remove("visible");
    notification.classList.add("hidden");
  });
});
